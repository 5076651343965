import { API_URL } from '../utils/constants';

require('es6-promise').polyfill();
const originalFetch = require('isomorphic-fetch');
const fetch = require('fetch-retry')(originalFetch);

const globalHeaders = {
  'Content-Type': 'application/json',
};
export const initialLogin = () => fetch(`${API_URL}/auth/initialLogin`, {
  retryOn: [500],
  retries: 3,
  method: 'POST',
  headers: globalHeaders,
})
  .then((res) => res.json())
  .catch((res) => {
    console.log(res, 'Error login');
  });

const token = new Promise(async (resolve) => {
  const tokenLS = localStorage.getItem('token');
  if (tokenLS) {
    resolve(tokenLS);
    return;
  }
  // eslint-disable-next-line no-use-before-define
  const tokenRes = await initialLogin();
  localStorage.setItem('token', tokenRes.token);
  resolve(tokenRes.token);
});

export const feedbackExtension = async (data) => {
  const authToken = await token;

  return fetch(`${API_URL}/users/feedback-extension`, {
    retryOn: [500],
    retries: 3,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${authToken}`,
      ...globalHeaders,
    },
    body: JSON.stringify(data),
  })
    .then((res) => res)
    .catch((res) => {
      console.log(res, 'Error feedback: uncorrect data');
    });
};
