import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import SecondStep from "src/pages/SecondStep/SecondStep";
import ThirdStep from "src/pages/ThirdStep/ThirdStep";
import FourthStep from "src/pages/FourthStep/FourthStep";
import FifthStep from "src/pages/FifthStep/FifthStep";
import Uninstall from "./pages/Uninstall";
import FirstStep from "./pages/FirstStep/FirstStep";
import SixthStep from "./pages/SixthStep/SixthStep";

import "./scss/main.scss";
import { getDomain } from "./utils/getDomain";
import { COPY_TITLE } from "./copies/copyTitle";

import RouterV3 from "./pages/v3/Router/Router";
import {
  HOME_PAGE,
  STEP_2_PAGE,
  STEP_3_PAGE,
  STEP_4_PAGE,
  STEP_5_PAGE,
  STEP_6_PAGE,
  STEP_7_PAGE,
  UNINSTALL_PAGE,
} from "./constants";
import NinthStep from "./pages/v3/NinthStep/NinthStep";
import { Router } from "./components/Router/Router";

const App = () => {
  const { ready } = useTranslation();

  if (!ready) {
    return <div />;
  }

  return (
    <BrowserRouter>
      <Helmet>
        <link rel="icon" href="/favicon.svg" />

        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Router />
    </BrowserRouter>
  );
};

export default App;
