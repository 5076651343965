const VERIFIED_DOMAINS = ['app.franceverif.fr', 'app.frverif.fr'];

export const getDomain = () => {
  const { host } = window.location;

  if (VERIFIED_DOMAINS.includes(host)) {
    return host;
  }

  return VERIFIED_DOMAINS[0];
};
