import React, { useRef, useState } from 'react';
import { ReactComponent as Logo } from 'src/svg/logo-welcome.svg';
import { ReactComponent as RemoveIcon } from 'src/svg/removeField.svg';

import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import imageFirstWebp from 'src/static/images/fourth-step/Frame 30357.webp';
import imageFirst2xWebp from 'src/static/images/fourth-step/Frame 30357@2x.webp';
import styles from './styleSixth.module.scss';
import classes from '../Welcome/style.module.scss';
import classesFirstStep from '../Welcome/compontents/styles/mainPage.module.scss';

const SixthStep = ({ t }) => {
  // Fields State
  const [fields, setFields] = useState([
    {
      value: '',
      id: 1,
    },
  ]);

  // Checkbox State
  const [checkbox, setCheckbox] = useState(null);

  const onChangeField = (e, id) => {
    const target = e.target.value.trim();
    const findIndex = fields.findIndex((el) => el.id === id);
    const newFields = [...fields];
    newFields[findIndex].value = target;
    setFields(newFields);
  };

  const onShowMoreField = () => {
    setFields([
      ...fields,
      {
        id: Date.now(),
        value: '',
      },
    ]);
  };
  const onChangeRadio = (e) => {
    setCheckbox(e.target.value);
  };

  const onSendHandler = async () => {
    if (checkbox === 'non') {
      window.location.href = 'https://franceverif.fr';
      return;
    }
    setTimeout(() => {
      window.location.href = 'https://franceverif.fr';
    }, 200);
  };
  const removeFieldHandler = (id) => {
    setFields([...fields.filter((el) => el.id !== id)]);
  };
  return (
    <div className={classes.root} id="sixth-step">
      <div className={classNames(classes.header)}>
        <div className={classes.logo}><a href="https://franceverif.fr/" target="_blank"><Logo /></a></div>
      </div>
      <div className={classes.body}>
        <div className={classesFirstStep.root}>
          <div
            className={classesFirstStep.imageSecond}
          >
            <picture>
              <source srcSet={`${imageFirstWebp} 1x, ${imageFirst2xWebp} 2x`} type="image/webp" />
              <img src={imageFirstWebp} srcSet={`${imageFirst2xWebp} 2x`} type="image/webp" alt={t('secondTitle')} />
            </picture>
          </div>
          <div className={classesFirstStep.content}>
            <div className={styles.title}>
              Envie de codes promos personnalisés?
            </div>
            <div style={{ display: 'flex', marginTop: 60 }}>
              <p className={styles.descr}>
                Envie de recevoir plus de codes promos sur vos produits favoris ? Renseignez 3 de vos produits préférés et nos Intelligences Artificielles se chargeront du reste!
              </p>
            </div>
            <div className={styles.categoryTitle}>Vos produits</div>
            <div id="form-step">
              <div className={styles.category}>
                {fields.map((el, i) => (
                  <div className={styles.rowField} key={el.id}>
                    {/* eslint-disable-next-line no-nested-ternary */}
                    <input
                      style={fields.length > 1 ? {
                        paddingRight: 44,
                      } : null}
                      type="text"
                      value={el.value}
                      onChange={(e) => onChangeField(e, el.id)}
                      maxLength={50}
                      name={el.id}
                      id={el.id}
                      className={styles.inputField}
                    />
                    {/* eslint-disable-next-line max-len */}
                    <label htmlFor={el.id} className={classNames(styles.label, { [styles.empty]: !el.value.length })}>Livres, vêtements...</label>
                    {fields.length > 1 && (
                      <button className={styles.removeButton} onClick={() => { removeFieldHandler(el.id); }}>
                        <RemoveIcon />
                      </button>
                    )}
                  </div>
                ))}
                {
                  fields.length < 3 && (
                  <div className={styles.addCategory}>
                    <button className={styles.addBtn} onClick={onShowMoreField} >
                      <span>Ajouter un champs</span>
                    </button>
                  </div>
                  )
                }
              </div>
              <div className={styles.check}>
                <div className={styles.checkRow}>
                  <input type="radio" id="oui" name="check" value="oui" onChange={onChangeRadio} className={classNames(styles.customCheck)} />
                  <label htmlFor="oui" className={classNames({ [styles.activeCheck]: checkbox === 'oui' })}>Oui ! Je veux recevoir des offres personnalisées de France Verif et de ses partenaires :)</label>
                </div>
                <div className={styles.checkRow}>
                  <input type="radio" id="non" name="check" value="non" onChange={onChangeRadio} className={classNames(styles.customCheck)} />
                  <label htmlFor="non" className={classNames({ [styles.activeCheck]: checkbox === 'non' })}>Non, je ne suis pas intéressé :/</label>
                </div>
                <div className={styles.btnIcon}>
                  <a target='_blank' href="https://franceverif.fr/politique-confidentialite" />
                </div>
              </div>
              <div className={styles.button}>
                <button id="form-step-btn" onClick={onSendHandler} className={classNames('next-button', styles.send)} disabled={(!checkbox || fields.find((el) => el.value.length < 2)) && checkbox !== 'non'}><span>Fermer cette fenêtre et commençer mon shopping !</span></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withTranslation('welcome')(SixthStep);
