import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import image from 'src/static/images/fifth-step/Group 30434.webp';
import image2x from 'src/static/images/fifth-step/Group 30434@2x.webp';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Logo } from '../../svg/logo-welcome.svg';
import classesFirstStep from '../Welcome/compontents/styles/mainPage.module.scss';
import classes from '../Welcome/style.module.scss';

const REQUIRED = 'Champs requis';
const NOT_VALID_EMAIL = 'Adresse email invalide';
const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const FifthStep = ({ t }) => {
  const history = useHistory();

  const [value, setValue] = useState();
  const [error, setError] = useState('Champs requis');
  const [touched, setTouched] = useState(false);

  const [checked, setChecked] = useState(false);

  return (
    <div className={classes.root}>
      <div className={classNames(classes.header)}>
        <div className={classes.logo}>
          <a href="https://franceverif.fr/" target="_blank"><Logo /></a>
        </div>
      </div>
      <div className={classes.body}>
        <div className={classesFirstStep.root}>
          <div
            className={classesFirstStep.imageSecond}
          >
            <picture>
              <img src={image} srcSet={`${image2x} 2x`} type="image/png" alt={t('firstTitle')} />
            </picture>
          </div>
          <div className={classesFirstStep.content}>
            <div className={classesFirstStep.title}>
              France Verif vérifie aussi les prix !
            </div>
            <div style={{ display: 'flex', marginTop: 60 }}>
              <p className={classesFirstStep.firstText}>
                <b>France Verif vérifie le prix du produit que vous regardez</b>
                {' '}
                et propose les meilleurs offres provenant de sites livrant en France et évalués comme fiables.
              </p>
              <svg style={{ flexShrink: 0, marginLeft: 20, marginRight: 80 }} width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="35" cy="35" r="35" fill="#83C428" />
                <g>
                  <path fillRule="evenodd" clipRule="evenodd" d="M35 57.4219C47.3833 57.4219 57.4219 47.3833 57.4219 35C57.4219 22.6167 47.3833 12.5781 35 12.5781C22.6167 12.5781 12.5781 22.6167 12.5781 35C12.5781 47.3833 22.6167 57.4219 35 57.4219ZM35 59.6094C48.5914 59.6094 59.6094 48.5914 59.6094 35C59.6094 21.4086 48.5914 10.3906 35 10.3906C21.4086 10.3906 10.3906 21.4086 10.3906 35C10.3906 48.5914 21.4086 59.6094 35 59.6094Z" fill="white" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M64.6989 9.59397C66.0658 10.9608 66.0658 13.1769 64.6989 14.5437L36.9906 42.2521C35.6238 43.6189 33.4077 43.6189 32.0409 42.2521L25.7214 35.9326C24.3546 34.5658 24.3546 32.3497 25.7214 30.9829C27.0883 29.616 29.3043 29.616 30.6712 30.9829L34.5157 34.8274L59.7492 9.59397C61.116 8.22714 63.3321 8.22714 64.6989 9.59397Z" fill="white" />
                </g>
              </svg>
            </div>
            <div className={classesFirstStep.inputWrapper}>
              <label>Mon adresse email*</label>
              <input
                name="email"
                id="get-email"
                placeholder="Adresse email"
                type="email"
                value={value}
                onBlur={(e) => {
                  setTouched(true);
                  setValue(e.target.value?.trim());
                }}
                onChange={(e) => {
                  setValue(e.target.value);

                  if (!e.target.value) {
                    setError(REQUIRED);
                    return;
                  }

                  if (!e.target.value.match(EMAIL_REGEX)) {
                    setError(NOT_VALID_EMAIL);
                  } else {
                    setError(null);
                  }
                }}
              />
              {
                error && touched ? (
                  <p className={classesFirstStep.error}>
                    {error}
                  </p>
                ) : (
                  <p className={classesFirstStep.note}>
                    * ce champs est obligatoire
                  </p>
                )
              }
            </div>
            <div className={classesFirstStep.accept} id="accept">
              <button className={classesFirstStep.acceptCheckbox} onClick={() => setChecked(!checked)}>
                <span style={checked ? { background: '#D1419D' } : {}} />
                <p>
                  J’accepte de recevoir les informations et offres commerciales de France Verif.
                </p>
                <a href="https://franceverif.fr/politique-confidentialite" target="_blank" rel="noreferrer">
                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.1666 3.5L19.0085 7.34183L10.8418 15.5085L12.4915 17.1582L20.6581 8.9915L24.5 12.8333V3.5H15.1666Z" fill="#0D83F0" />
                    <path d="M22.1667 22.1667H5.83333V5.83333H14L11.6667 3.5H5.83333C4.5465 3.5 3.5 4.5465 3.5 5.83333V22.1667C3.5 23.4535 4.5465 24.5 5.83333 24.5H22.1667C23.4535 24.5 24.5 23.4535 24.5 22.1667V16.3333L22.1667 14V22.1667Z" fill="#0D83F0" />
                  </svg>
                </a>
              </button>
              <button
                id="form-accept"
                disabled={!checked || error}
                className={classNames('next-button', classesFirstStep.btn)}
                onClick={() => {
                  history.push('/bienvenue/step-6');
                }}
              >
                Fermer cette fenêtre
                {' '}
                <br />
                et commençer mon shopping !
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation('welcome')(FifthStep);
