import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import image from 'src/static/images/first-step/screen-Chrome.png';
import imageWebp from 'src/static/images/first-step/screen-Chrome.webp';
import imageSafari from 'src/static/images/first-step/screen-Safari.png';
import imageSafariWebp from 'src/static/images/first-step/screen-Safari.webp';
import image2x from 'src/static/images/first-step/screen-Chrome@2x.png';
import image2xWebp from 'src/static/images/first-step/screen-Chrome@2x.webp';
import image2xSafari from 'src/static/images/first-step/screen-Safari@2x.png';
import image2xSafariWebp from 'src/static/images/first-step/screen-Safari@2x.webp';
import { useHistory } from 'react-router-dom';
import { detect } from 'detect-browser';
import { ReactComponent as Logo } from '../../svg/logo-welcome.svg';
import classesFirstStep from '../Welcome/compontents/styles/mainPage.module.scss';
import classes from '../Welcome/style.module.scss';

const FirstStep = ({ t }) => {
  const history = useHistory();

  const browser = detect().name;

  useEffect(() => {
    dataLayer?.push({'event': 'vue_onboarding_v1'});
  }, []);

  useEffect(() => {
    if (browser !== 'safari' && browser !== 'chrome') {
      history.push('/bienvenue/step-3');
    }
  }, [browser]);

  return (
    <div className={classes.root}>
      <div className={classNames(classes.header)}>
        <div className={classes.logo}> <a href="https://franceverif.fr/" target="_blank"><Logo /></a></div>
      </div>
      <div className={classes.body}>
        <div className={classesFirstStep.root}>
          <div
            className={classesFirstStep.image}
          >
            <picture>
              <source srcSet={`${browser === 'safari' ? imageSafariWebp : imageWebp} 1x, ${browser === 'safari' ? image2xSafariWebp : image2xWebp} 2x`} type="image/webp" />
              <img src={browser === 'safari' ? imageSafari : image} srcSet={`${browser === 'safari' ? image2xSafari : image2x} 2x`} type="image/png" alt={t('firstTitle')} />
            </picture>
          </div>
          <div className={classesFirstStep.content}>
            <div className={classesFirstStep.title}>
              {
                browser === 'safari' ? (
                  'Merci d’avoir installé l’assistant achat IA France Verif sur Safari !'
                ) : ('Merci d’avoir installé l’assistant achat IA France Verif sur Chrome !')
              }
            </div>
            <p className={classesFirstStep.firstText}>
              L’icône France Verif doit apparaitre dans la barre supérieure de votre navigateur. Sur
              {' '}
              <b>{ browser === 'safari' ? 'Safari' : 'Chrome' }</b>
              , elle se situe dans la partie
              {' '}
              {
                browser === 'safari' ? <b>à gauche (ou à droite) du champ de recherche.</b> : <b>à droite du champ de recherche.</b>
              }
            </p>
            <p className={classesFirstStep.secondText}>
              <b>
                L’icône France Verif devrait apparaitre à côté de la barre de recherche. Est-ce bien le cas ?
              </b>
            </p>
            <div className={classesFirstStep.buttons}>
              <button
                type="button"
                className={classNames('next-button', classesFirstStep.btn)}
                id="toSecondPage"
                onClick={() => {
                  history.push('/bienvenue/step-2');
                }}
              >
                Non
              </button>
              <button
                type="button"
                className={classNames('next-button', classesFirstStep.btn)}
                id="toSecondPage"
                onClick={() => {
                  history.push('/bienvenue/step-3');
                }}
              >
                Oui
              </button>
            </div>
            <a
              href=""
              className={classesFirstStep.underlineAction}
              onClick={(e) => {
                e.preventDefault();
                history.push('/bienvenue/step-2');
              }}
            >
              Besoin d’aide ?
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation('welcome')(FirstStep);
