import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import classnames from 'classnames';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga4';
import { COPY_TITLE } from '../../../copies/copyTitle';
import { COPY_SMALL_LOGO } from '../../../copies/copyLogos';
import ButtonUninstall from '../../../components/ButtonUninstall';
import styles from './style.module.scss';
import TextareaUninstall from '../../../components/UI/TextareaUninstall';
import Loader from '../../../components/Loader';
import { feedbackExtension } from '../../../services/api';
import { getDomain } from '../../../utils/getDomain';
import { getParams } from '../../../utils';
import { detectExtensionBrowser } from '../../../utils/detectBrowser';
import InputText from '../../../components/UI/Inputs/InputText';

const Uninstall = ({ t }) => {
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const Shema = Yup.object().shape({
    email: Yup.string()
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        t('invalidEmail'),
      )
      .required(t('requiredField')),
    text: Yup.string().required(t('requiredField')),
  });

  useEffect(() => {
    const { cid } = getParams(window.location.href);
    document.querySelector('#root').classList.add('uninstall');
    if (cid) {
      ReactGA.initialize('G-F3JF9CXVS2', {
        gaOptions: {
          clientId: cid,
        },
      });

      const { utm_source } = getParams(window.location.url);
      const { utm_campaign } = getParams(window.location.url);
      ReactGA.event('uninstall_visited', {
        data_source: detectExtensionBrowser(),
        initial_source: utm_source,
        initial_campaign: utm_campaign,
      });
    }
  }, []);

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    // setErrors,
  } = useFormik({
    validationSchema: Shema,
    validateOnChange: true,
    initialValues: {
      email: '',
      text: '',
    },
    onSubmit: async ({ email, text }) => {
      setLoading(true);
      const datas = {
        email,
        text,
        client: 'uninstall page',
      };
      const response = await feedbackExtension(datas);
      console.log(response);
      setLoading(false);

      const { cid } = getParams(window.location.href);

      if (cid) {
        const { utm_source } = getParams(window.location.url);
        const { utm_campaign } = getParams(window.location.url);
        ReactGA.event('uninstall_commented', {
          data_source: detectExtensionBrowser(),
          initial_source: utm_source,
          initial_campaign: utm_campaign,
          comment: text,
        });
      }

      if (response && response.status === 201) {
        setLoaded(true);
      }
    },
  });

  return (
    <div className={classnames(styles.uninstall)}>
      <Helmet>
        <title>
          {COPY_TITLE[getDomain()]}
          {' '}
          -
          {' '}
          {t('pageTile', { title: COPY_TITLE[getDomain()] })}
        </title>
      </Helmet>
      <div className={classnames(styles.wrap)}>
        <div className={classnames(styles.logo)}>
          {COPY_SMALL_LOGO[getDomain()]}
        </div>
        <h1>{t('title', { title: COPY_TITLE[getDomain()] })}</h1>
        <p
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: t('description') }}
        />

        {/* eslint-disable-next-line no-nested-ternary */}
        {!loaded ? (
          !loading ? (
            <form className={classnames(styles.feedbackForm)}>
              <InputText
                className={styles.input}
                placeholder={t('inputPlaceholder')}
                onChange={handleChange}
                onBlur={handleBlur}
                name="email"
                id="email"
                error={errors.email}
                value={values.email}
              />
              {/* <InputUninstall */}

              {/* /> */}
              <TextareaUninstall
                placeholder={t('textAreaPlaceholder')}
                onChange={handleChange}
                onBlur={handleBlur}
                name="text"
                id="text"
                error={errors.text}
                value={values.text}
              />
              <ButtonUninstall
                disabled={
                  Object.keys(errors).length || !values.text || !values.email
                }
                text={t('send')}
                onClick={handleSubmit}
              />
            </form>
          ) : (
            <div className={styles.loader}>
              <Loader />
            </div>
          )
        ) : (
          <div className={styles.feedbackSuccess}>{t('thank')}</div>
        )}
      </div>
    </div>
  );
};

export default withTranslation('uninstall')(Uninstall);
