import React from 'react';
import classnames from 'classnames';
import styles from './style.module.scss';

const TextareaUninstall = (props) => {
  const { disabled, error, classname } = props;
  return (
    <>
      <textarea
        type="text"
        {...props}
        className={classnames(classname, styles.textarea, {
          [styles.textareaDisabled]: disabled,
          [styles.textareaError]: error,
        })}
      />
      {error ? <span className={styles.textareaErrorText}>{error}</span> : null}

    </>
  );
};

export default TextareaUninstall;
