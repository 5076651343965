// export const API_URL = 'https://dev-api-gateway.ecomcheck.ml';

// export const API_URL = 'https://stage-api-gateway.ecomcheck.ml';

import { getDomain } from './getDomain';

const API_MAP = {
  'app.franceverif.fr': 'https://api-gateway.franceverif.fr',
  'app.frverif.fr': 'https://api-gateway.frverif.fr',
};

export const API_URL = API_MAP[getDomain()];
